body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

* {
    margin: 0;    
}
.body {
    background-color: transparent;    
}


.video-responsive {
    overflow: hidden;
    padding-bottom: 30%;
    position: relative;
    height: 0;  
  }
  
.video-responsive iframe {
    left: 25%;
    top: 0;
    height: 100%;
    width: 50%;
    position: absolute; 
    padding-bottom: 1%;   
 }
  
.header_about {
    color: white;
    font-size: 40px;
    padding-bottom: 1%;
}

.description_text {
    justify-content: center;
    margin-left: 8%;
    margin-right: 8%;
    color: white;
    padding-top: -30%;
    font-size: 20px;
}
* {
    margin: 0;    
}
body {
    background-color: transparent;   
   
   
}

.text_contact {
    font-size: 20px;
    justify-content: center;
    margin-left: 8%;
    margin-right: 8%;
    color: rgba(248, 246, 246, 0.925); 
}

.header_contact {
    color: white;
    font-size: 40px;
    padding-bottom: 1%;
}
* {
    margin: 0;
    
}
body {
    background-color: transparent;

   
    
}






 

* {
    margin: 0;    
}
body {
    background-color: transparent;   
     
}


.text {
    font-size: 12px;
    justify-content: center;
    margin-left: 8%;
    margin-right: 8%;
    color: rgba(248, 246, 246, 0.925);
    
}

.header_policy {
    color: white;
    font-size: 40px;
    padding-bottom: 1%;
}

.source_link {
    font-size: 10px;
    justify-content: center;
    margin-left: 8%;
    margin-right: 8%;
    color: white;
    
}




* {
    margin: 0;    
}
body {
    background-color: transparent;   
   
   
}

.text {
    font-size: 12px;
    justify-content: center;
    margin-left: 8%;
    margin-right: 8%;
    color: rgba(248, 246, 246, 0.925); 
}

.header_founder {
    color: white;
    font-size: 40px;
    padding-bottom: 1%;
}

.source_link {
    font-size: 10px;
    justify-content: center;
    margin-left: 8%;
    margin-right: 8%;
    color: white;
    
}

.content_founder {
    font-size: 20px;
    justify-content: center;
    color: white;
    margin-left: 10%;
    margin-right: 8%;
}

.sujoy_picture {
    justify-content: center;
    


}









