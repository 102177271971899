* {
    margin: 0;    
}
.body {
    background-color: transparent;    
}


.video-responsive {
    overflow: hidden;
    padding-bottom: 30%;
    position: relative;
    height: 0;  
  }
  
.video-responsive iframe {
    left: 25%;
    top: 0;
    height: 100%;
    width: 50%;
    position: absolute; 
    padding-bottom: 1%;   
 }
  
.header_about {
    color: white;
    font-size: 40px;
    padding-bottom: 1%;
}

.description_text {
    justify-content: center;
    margin-left: 8%;
    margin-right: 8%;
    color: white;
    padding-top: -30%;
    font-size: 20px;
}