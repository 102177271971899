
* {
    margin: 0;    
}
body {
    background-color: transparent;   
   
   
}

.text {
    font-size: 12px;
    justify-content: center;
    margin-left: 8%;
    margin-right: 8%;
    color: rgba(248, 246, 246, 0.925); 
}

.header_founder {
    color: white;
    font-size: 40px;
    padding-bottom: 1%;
}

.source_link {
    font-size: 10px;
    justify-content: center;
    margin-left: 8%;
    margin-right: 8%;
    color: white;
    
}

.content_founder {
    font-size: 20px;
    justify-content: center;
    color: white;
    margin-left: 10%;
    margin-right: 8%;
}

.sujoy_picture {
    justify-content: center;
    


}








